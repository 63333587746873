import { useState, useEffect } from 'react';


export const getSessionStorageOrDefault = (key, defaultValue) => {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export const useSessionStorage = () => {

  const store = (key, value) => {

  sessionStorage.setItem(key, JSON.stringify(value))
  
  }

  const get = (key) => {

    const value = sessionStorage.getItem(key)

    return value;
    }

  const storageHelpers = { store, get }

  return storageHelpers;

}