import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'


const customPlugin = {
  name: 'bb-plugin',
  page: ({ payload }) => {
    // console.log('page view', payload)
  },
  track: ({ payload }) => {
    // console.log('track event', payload)
  }
}
/* Initialize analytics & load plugins */
export const analytics = Analytics({
  app: 'bb-web',
  plugins: [
    customPlugin,
    googleAnalytics({ trackingId:"UA-187847054-1" }),
    googleTagManager({ containerId: 'GTM-56XSQ27' }),
  ]
})
