import styled from 'styled-components'
import Colors from '../../Utils/Colors'
import {screen, PageWrapper } from '../../Utils/globalStyles'

export const DocPageWrapper = styled(PageWrapper)`
  position: relative;
  padding-top:130px;


.box {
  border: 1px solid #000;
  padding: 25px;
}


.strong {
  font-weight: 700;
}

  table, th, td {
  border: 1px solid #666;
  border-collapse: collapse;
}

  .section {
    padding-bottom:80px;
  }
  .subtitle {
    padding-bottom: 40px;
  }

  p {
    padding-bottom:40px;
    @media(${screen.mobile}) {
      padding-bottom:30px;
  }
  }
  .space {
    flex: 1;
  }

  .pic-wrapper{
    flex: 2;
    @media(${screen.desktop}) { height: calc(60vw/1.8); }
    @media(${screen.iPad}) { height: calc((100vw - 65px * 2)/1.8); }
    @media(${screen.mobile}) { height: calc((100vw - 20px * 2)/1.8); }

    img, video{
    max-width:100%;
    width: inherit;
    height: inherit;
    object-fit: contain;
    }
  }

  .text-wrapper{
  flex: 2;
  padding-bottom:0px;
  @media(${screen.mobile}) {
      padding-bottom:30px;
  }
  }
  .text-block {
  max-width:800px;
  padding-top:50px;
  padding-bottom:50px;

  @media(${screen.mobile}) {
  padding-top:50px;
  padding-bottom:50px;
  }

  }


  .subheader {
    flex:1;
    @media(${screen.mobile}) {
      padding-top:50px;
  }
  }

  .leftTitle {
    flex: 1;
    @media(${screen.mobile}) {
  display:none;
  }

  }
  .rightSubtitle {
    flex: 2;
  }



`
