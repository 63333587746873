import React, { useEffect } from 'react'
import {
  ElectricalRequirementsPageWrapper,
} from './styles'
import { Link } from 'react-router-dom'

const ElectricalRequirements =  ({ setFooterReady }) => {

  useEffect(() => {setFooterReady(true)
    return (() => setFooterReady(false))
  },[setFooterReady])

  return <ElectricalRequirementsPageWrapper>
    <div className="subheader section">
      <h1 className="leftTitle"> Electrical Requirements</h1>
    </div>


    <div className="section">

        <div className="text-block">
            <Link to="/electrical-requirements-console">
                <h3 className="subtitle"> Console </h3>
            </Link>
             <br></br>
             <Link to="/electrical-requirements-workstation">
                <h3 className="subtitle"> Workstation </h3>
            </Link>
        </div>
    </div>
  </ElectricalRequirementsPageWrapper>
}

export default ElectricalRequirements
