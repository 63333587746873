const custom = (
  hue=`hsla(${Math.floor(Math.random() *359)}`,
  saturation='100%',
  light='50%',
  alpha=.1
) => `background-color: ${hue}, ${saturation}, ${light}, ${alpha});`

// const rando = Math.floor(Math.random() *359)
const Colors = {
  rng: () => `hsla(${Math.floor(Math.random()*359)},50%,50%,.9)`,
  bgc: () => `background-color: hsla(${Math.floor(Math.random()*359)},50%,50%,1);`,
  custom: () => custom,
  //   saturation='100%',
  //   light='50%',
  //   alpha=.1
  // ) => `background-color: hsla(${Math.floor(Math.random() *359)}, ${saturation}, ${light}, ${alpha});`,

/** figma */
  Solid:{
    Dark       : 'hsl( 18,  7%, 28%)',
    Light      : 'hsl(  0,100%,100%)',
    Grey       : 'hsl( 24,  3%, 64%)',
    Blue       : 'hsl(202, 57%, 47%)',
    Error      : 'hsl(  0, 57%, 59%)',
    Tan        : 'hsl( 43, 19%, 93%)',
    Alert      : 'hsl( 36,100%, 67%)',
  },
  Translucent: {
    Grey       : 'hsla(  0,  0%, 67%, 0.5)',
    LightBrown : 'hsla(  0, 10%, 71%, 0.2)',
    LightTan   : 'hsla( 38, 16%, 76%, 0.15)',
    Brown      : 'hsla( 31, 13%, 62%, 0.5)',
    Night      : 'hsla(204, 24%, 59%, 0.2)',
    Dark       : 'hsla(  0, 12%,  5%, 0.4)',
  },
  bgcWhite     : 'hsl( 30,  2%, 94%)',  // primary background
  gray         : 'hsl(214,  8%, 62%)',
  Grey1        : 'hsl(  0,  0%, 50%)',
  Grey2        : 'hsl(  0,  0%, 70%)',
  Footer       : 'hsl( 38, 18%, 88%)',
  WhiteOak     : 'hsl( 30, 71%, 97%)',
  WhiteWood    : 'hsl(  0,  0%, 98%)',
  offwhite     : 'hsl(  0,  0%, 95%)',
  white        : 'hsl(  0,  0%,100%)',
  black        : 'hsl(  0,  0%,  0%)',
  red          : 'hsl(  0,100%, 50%)',
  btnBlue      : 'hsl(210,100%, 42%)',
  btnBlueHover : 'hsl(210,100%, 44%)',
}
export default Colors;
