import BBSElectricalConsole from 'assets/misc/electrical-requirements-console.jpg'

const InstallPageConsole = () => <div>
  <img
    src={BBSElectricalConsole}
    alt='console eletrical diagram'
    style={{width:'100%'}}
  />
</div>

export default InstallPageConsole
