import styled from "styled-components";
import Colors from "./Colors";
export const barHeight = "73px";

export const menuHeight = `${720}px`; //355
export const mobileMenuHeight = `${75 + 530 + 49}px`; //555
export const navDropTime = "300ms";
export const borderRadii = "border-radius: 10px;";
export const activeNodeStyle = {
  backgroundColor:"white",
  color:"black",
  opacity:1,
};

export const textSizes = {
  header: "",
  subHeader: "25px",
  default: "",
  secondary: "20px",
  caption: "12px",
  subText: "",
};

export const screen = {
  mobile: "max-width: 567px",
  tab2: "max-width: 767px",
  iPad: "max-width: 990px",
  desktop: "min-width: 991px",
  desktopL: "min-width: 1800px",
};

export const WebsiteWrapper = styled.div`
  min-height: calc(100vh);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  ${"" /* min-width: 100vw; */}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};

  .load {
    color: rgba(0, 0, 0, 0);
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  .nav-backdrop {
    position: fixed;
    background: hsla(0, 0%, 0%, 0.6);
    top: 0px;
    left: 0;
    cursor: pointer;
    right: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    transition: all ${navDropTime} ease-in-out;
    :hover {
      background: hsla(0, 0%, 0%, 0.65);
    }
  }

  .nav-backdrop.menu-visible {
    pointer-events: auto;
    opacity: 1;
    z-index: 4;
  }

  .textGrey {
    color: ${Colors.Grey2};
  }

  .paddingRight {
    padding-right: 80px;
    @media (${screen.iPad}) {
      padding-right: 0px;
    }
    @media (${screen.desktop}) {
      padding-right: 40px;
    }
  }
  .paddingLeft {
    padding-left: 80px;
    @media (${screen.iPad}) {
      padding-left: 0px;
    }
    @media (${screen.desktop}) {
      padding-left: 40px;
    }
  }
  .paddingTop {
    padding-top: 25px;
    @media (${screen.iPad}) {
      padding-top: 20px;
    }
  }
  .textCenter {
    text-align: center;
  }
`;

export const PageWrapper = styled.section`
  .topSpace {
    padding-top: 100px;
  }
  .bottomSpace {
    padding-bottom: 100px;
  }

  .section {
    display: flex;

    @media (${screen.desktop}) {
      padding-bottom: 140px;
      padding-left: 85px;
      padding-right: 95px;
    }

    @media (${screen.iPad}) {
      padding-bottom: 60px;
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex-direction: column;
    }

    @media (${screen.mobile}) {
      padding-bottom: 60px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const BBButton = styled.button`
  border: none;
  cursor: pointer;
  text-decoration: none;
  background: ${Colors.btnBlue};
  border: 2px solid ${Colors.btnBlue};
  :hover {
    background: ${Colors.btnBlueHover};
  }
  transition: all 0.4s ease-in-out;
  color: ${Colors.Solid.Light};
  text-align: center;
  border-radius: 100px;
  line-height: unset;

  .link {
    text-decoration: none;
  }

  &.center {
    margin: auto;
  }
`;

export const SideNav = styled.nav`
  position: fixed;
  top:150px;


  @media (${screen.iPad}) {
    position: unset;
  }

  ul {
    margin: 0;
  }
`;

export const NavTopic = styled.li`
  font-weight: 300px;
  list-style-type: none;
  margin: 0px 0px 6px;
  text-decoration-line: underline;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 3px;
  text-decoration-color: ${({ isActive }) =>
    isActive ? "#000" : "transparent"};
  transition: text-decoration 0.3s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  width: fit-content;

  :hover {
    text-decoration-color: #000;
    cursor: pointer;
  }

  @media (${screen.desktop}) {
    margin: 0px 0px 3px;
  }

  @media (${screen.iPad}) {
    display: inline-block;
    padding-right: 30px;
  }
`;

export const LoadingScreenWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: fixed;
  /* background-color:${Colors.offwhite}; */
  width: 100vw;
  height: 100vh;
`;

export const DueTodayBox = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  p {
    margin: 0;
    color: ${Colors.Solid.Grey};
  }

  div {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .buttonWrapper {
    margin-left:auto;
    text-decoration: none;
  }

  span {
    font-size: 13px;
  }

  &.top-one {
    margin: 0;
    display: flex;
    flex-direction: column;
    h3 {
      font-weight: 700;
    }
    a {
      align-self: flex-end;
    }
    button {
      width: unset;
    }
  }
`;
