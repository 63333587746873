import React from "react";
import { Link } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import { isMobile } from "react-device-detect";
import { ReactComponent as LogoText } from "assets/icons/BumblebeeWordmark.svg";
import { ReactComponent as BBLogo } from "assets/icons/BumblebeeLogo.svg";
import { useScrollOffset } from "Utils/hooks";
import { isHeroInViewAtom } from "Utils/store";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";
import { NavLinkMenu, NavBarWrapper, ImgWrapper, NavWrapper } from "./styles";
import { BBButton } from "Utils/globalStyles";
import Colors from "Utils/Colors";
import { useSessionStorage } from "Utils/hooks";

const NavBar = ({ isNavMenuUp, setNavMenuUp, dismissMenu }) => {
  const { isTopOfPage } = useScrollOffset();
  const [isHeroInView] = useAtom(isHeroInViewAtom);
  const { pathname } = useLocation();
  const toggleMenu = () => {
    setNavMenuUp((wasUp) => !wasUp);
  };
  const isNavDark =
    isMobile || isNavMenuUp || !(!isMobile && isHeroInView) || pathname !== "/";
  const logoColor = isNavDark ? Colors.Solid.Dark : Colors.Solid.Light;
  const { store } = useSessionStorage();

  return (
    <NavWrapper id="nav-wrapper"
    className={'pointer-events-none'}>
      <NavLinkMenu
        id="nav-menu"
        className={`transition-all pointer-events-none duration-300 translate-y-[800px] top-[-800px] flex px-2 sm:px-10 z-50 py-1 sm:py-3 bg-transparent  justify-between ${
          isNavDark ? "bg-white" : "bg-transparent"
        }`}
      >

<ImgWrapper
            className={`${isMobile ? "visible" : "hidden"} relative cursor-pointer pointer-events-auto`}
            onClick={toggleMenu}
          >
            <Hamburger
              size={29}
              color={logoColor}
              toggled={isNavMenuUp}
              aria-label="Toggle menu open"
            />
          </ImgWrapper>

<div className={`${isMobile ? "absolute m-auto" : "relative ml-0"} left-0 right-0 top-0 bottom-0`}>
          <Link to="/" onClick={dismissMenu} className={`${isMobile ? "mx-auto" : "mr-auto"} absolute left-0 right-0 top-0 bottom-0 pointer-events-auto  my-auto logoWrapper flex active gap-4`}>
          <ImgWrapper className="my-auto mx-auto">
            <BBLogo width="35px" height="35px" stroke={logoColor} />
          </ImgWrapper>
          <LogoText
            width="100px"
            height="20px"
            fill={logoColor}
            stroke={logoColor}
            className={`${isTopOfPage && !isMobile ? "visible" : "hidden"} my-auto`}
          />
        </Link>
        </div>

        <div className="noselect pointer-events-auto flex space-x-7 ml-auto">
          <div className={`${!isMobile ? "visible" : "hidden"}`}>

          <Link onClick={dismissMenu} to="/press" className="">
              <h2
                className={`transition-all text-base py-2 px-5 bg-gray-500 bg-opacity-0 hover:bg-opacity-10 rounded-full ${
                  isNavDark ? "text-black" : "text-white"
                }`}
              >
                Press
              </h2>
            </Link>


            <Link onClick={dismissMenu} to="/developers" className="">
              <h2
                className={`transition-all text-base py-2 px-5 bg-gray-500  bg-opacity-0 hover:bg-opacity-10 rounded-full ${
                  isNavDark ? "text-black" : "text-white"
                }`}
              >
                Developers
              </h2>
            </Link>

            <Link onClick={dismissMenu} to="/build-yours" className="">
              <h2
                className={`transition-all text-base py-2 px-5 bg-gray-500 bg-opacity-0 hover:bg-opacity-10 rounded-full ${
                  isNavDark ? "text-black" : "text-white"
                }`}
              >
                Design Studio
              </h2>
            </Link>
          </div>

          <Link
            onClick={() => {
              store("checkout_option", "Navbar Order Button");
              dismissMenu();
            }}
            to="/order"
            className="pointer-events-auto my-auto"
          >
            <BBButton className="text-base py-2 px-5 pointer-events-auto m-auto">Order Now</BBButton>
          </Link>
        </div>

        {/* <div className="noselect grid grid-flow-col grid-cols-6"> */}
        {/* <Link onClick={dismissMenu} to="/contact">
          <h3 className="subLinks noselect text-xl col-start-1">Contact</h3>
          </Link>
          <Link onClick={dismissMenu} to="/gallery">
            <h3 className="subLinks noselect text-xl col-start-6">Gallery</h3>
            </Link> */}
        {/* <Link onClick={()=> {store("checkout_option", "Navbar Order Button"); dismissMenu();}} to="/order" className="col-start-6 ml-auto">
          <BBButton>Order Now</BBButton>
        </Link> */}
        {/* </div> */}
      </NavLinkMenu>



      <NavLinkMenu
        id="nav-menu-mobile"
        className={`transition-all pointer-events-none duration-300 flex z-20 px-3 h-[100vh] justify-between ${
          isNavDark ? "bg-white" : "bg-transparent"
        } ${!isMobile ? "visible" : "visible"} ${
          !isNavMenuUp ? "top-[-100vh]" : "top-[0vh]"
        }`}
      >
        <div className="noselect flex space-y-12 mr-auto flex-col pt-10">
          <div className="flex flex-col space-y-4">
          <Link onClick={dismissMenu} to="/developers" className="pointer-events-auto">
            <h2
                className={`text-2xl py-2`}
            >
              Developers
            </h2>
          </Link>

          <Link onClick={dismissMenu} to="/build-yours" className="pointer-events-auto">
            <h2
                className={`text-2xl py-2`}
            >
              Design Studio
            </h2>
          </Link>

          <Link onClick={dismissMenu} to="/press" className="pointer-events-auto">
            <h2
               className={`text-2xl py-2`}
            >
              Press
            </h2>
          </Link>

          </div>

<div className="space-y-4 flex flex-col pt-8">

          <Link onClick={dismissMenu} to="/contact" className="pointer-events-auto">
            <h2
                 className={`text-lg py-2`}
            >
              Contact
            </h2>
          </Link>


          <Link onClick={dismissMenu} to="/gallery" className="pointer-events-auto">
            <h2
                className={`text-lg py-2`}
            >
              Gallery
            </h2>
          </Link>


          <Link onClick={dismissMenu} to="/faq" className="pointer-events-auto">
            <h2
              className={`text-lg py-2`}
            >
              FAQ
            </h2>
          </Link>
          </div>

          <div className="flex flex-col space-y-4">
          <a
              target="_blank"
              rel="noreferrer"
              className="text-base text-gray-500"
              href="https://www.instagram.com/bumblebeespaces/"
            >
              Instagram
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              className="text-base text-gray-500"
              href="https://twitter.com/bumblebeespaces"
            >
              Twitter
            </a>
          </div>
        </div>

        {/* <div className="noselect grid grid-flow-col grid-cols-6"> */}
        {/* <Link onClick={dismissMenu} to="/contact">
          <h3 className="subLinks noselect text-xl col-start-1">Contact</h3>
          </Link>
          <Link onClick={dismissMenu} to="/gallery">
            <h3 className="subLinks noselect text-xl col-start-6">Gallery</h3>
            </Link> */}
        {/* <Link onClick={()=> {store("checkout_option", "Navbar Order Button"); dismissMenu();}} to="/order" className="col-start-6 ml-auto">
          <BBButton>Order Now</BBButton>
        </Link> */}
        {/* </div> */}
      </NavLinkMenu>

      {/* <NavBarWrapper
        id="nav-bar"
        className={`${isNavMenuUp ? "bar-visible" : ""}`}
      >
        <Link to="/" onClick={dismissMenu} className={"logoWrapper active"}>
          <ImgWrapper>
            <BBLogo width="35px" height="35px" stroke={logoColor} />
          </ImgWrapper>
          <LogoText
            width="100px"
            height="20px"
            fill={logoColor}
            stroke={logoColor}
            className={isTopOfPage && !isNavMenuUp ? "show" : "show hide"}
          />
        </Link>
      </NavBarWrapper> */}
    </NavWrapper>
  );
};

export default NavBar;
