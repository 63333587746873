import React, {
  lazy,
  Suspense,
  useState,
  useEffect,
} from 'react';
import {
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import { useGaTracker } from './Utils/hooks'
import { useAnalytics } from 'use-analytics';

import NavBar from './Components/NavBar';
import { WebsiteWrapper } from './Utils/globalStyles';


import TermsPage from './Pages/Terms/TermsPage'
import PrivacyPage from './Pages/Terms/PrivacyPage'
import BetaTermsPage from './Pages/Terms/BetaTermsPage'
import CondoTermsPage from './Pages/Terms/CondoTermsPage'
import PreOrderAgreementPage from './Pages/Terms/PreOrderAgreementPage'

import ElectricalRequirementsPage from './Pages/Install/ElectricalRequirementsPage'
import InstallPageConsole from './Pages/Install/InstallPageConsole'
import InstallPageWorkstation from './Pages/Install/InstallPageWorkstation'

const Footer = lazy(() => import('./Components/Footer'))
const FAQPage = lazy(() => import('./Pages/FAQ/FAQPage'))
const HomePage = lazy(() => import('./Pages/Home/'))
const DevelopersPage = lazy(() => import('./Pages/Developers/DevelopersPage'))
const AboutPage = lazy(() => import('./Pages/About/AboutPage'))
const NotFoundPage = lazy(() => import('./Pages/NotFound/NotFoundPage'))
const JobsPage = lazy(() => import('./Pages/Jobs/JobsPage'))
const GalleryPage = lazy(() => import('./Pages/Gallery/GalleryPage'))
const ContactPage = lazy(() => import('./Pages/Contact/ContactPage'))
const PressPage = lazy(() => import('./Pages/Press/PressPage'))
const PreOrderPage = lazy(() => import('./Pages/PreOrder/PreOrderPage'))
const BuildYoursPage = lazy(() => import('./Pages/Build-Yours/BuildYoursPage'))

const BBSite = () => {
  let location = useLocation();
  const analytics = useAnalytics()

  const [isNavMenuUp, setNavMenuUp] = useState(false)
  const [isFooterReady, setFooterReady] = useState(false)
  useGaTracker()

  useEffect(()=>{
    window.scrollTo(0, 0)
    analytics.page()
  }, [location, analytics])

  const dismissMenu = () => {
    // console.log('dismissing')
    setNavMenuUp(false)
  }

return <WebsiteWrapper id='main-app'>

{(!['/electrical-requirements-console', '/electrical-requirements-console/',
    '/electrical-requirements-workstation', '/electrical-requirements-workstation/'].includes(location.pathname)) && <NavBar id='nav'
    dismissMenu={dismissMenu}
    isNavMenuUp={isNavMenuUp}
    setNavMenuUp={setNavMenuUp}
  />}

  <Routes>
    <Route path="/" element={
      <Suspense fallback={<div className="load">Loading...</div>}>
        <HomePage setFooterReady={setFooterReady} />
      </Suspense>
    } />
    <Route path="about" element={
      <Suspense fallback={<div className="load">Loading About...</div>}>
        <AboutPage setFooterReady={setFooterReady}/>
      </Suspense>
    } />
       <Route path="developers" element={
      <Suspense fallback={<div className="load">Loading Developers...</div>}>
        <DevelopersPage setFooterReady={setFooterReady}/>
      </Suspense>
    } />
    <Route path="jobs" element={
      <Suspense fallback={<div className="load">Loading Jobs...</div>}>
        <JobsPage setFooterReady={setFooterReady}/>
      </Suspense>
    } />
    <Route path="contact" element={
      <Suspense fallback={<div className="load">Loading Contact...</div>}>
        <ContactPage setFooterReady={setFooterReady}/>
      </Suspense>
    } />
        <Route path="press" element={
      <Suspense fallback={<div className="load">Loading Press...</div>}>
        <PressPage setFooterReady={setFooterReady}/>
      </Suspense>
    } />
    <Route path="build-yours" element={
      <Suspense fallback={<div className="load">Loading Build Yours...</div>}>
        <BuildYoursPage />
      </Suspense>
    } />
    <Route path="order" element={
      <Suspense fallback={<div className="load">Loading PreOrder...</div>}>
        <PreOrderPage />
      </Suspense>
    } />
    <Route path="gallery" element={
      <Suspense fallback={<div className="load">Loading Gallery...</div>}>
        <GalleryPage setFooterReady={setFooterReady}/>
      </Suspense>
    } />
    <Route path="faq" element={
      <Suspense fallback={<div className="load">Loading FAQ...</div>}>
        <FAQPage setFooterReady={setFooterReady}/>
      </Suspense>
    } />
    <Route path="electrical-requirements" element={
      <Suspense fallback={<div className="load">Loading Electrical Requirements...</div>}>
        <ElectricalRequirementsPage setFooterReady={setFooterReady}/>
      </Suspense>
    } />
    <Route path="*" element={
      <Suspense fallback={<div className="load">Loading...</div>}>
        <NotFoundPage setFooterReady={setFooterReady}/>
      </Suspense>
    } />

    {/* TERMS OF SERVICES AGREEMENTS */}
    <Route path="terms" element={ <TermsPage /> } />
    <Route path="privacy" element={ <PrivacyPage /> } />
    <Route path="beta-terms" element={ <BetaTermsPage /> } />
    <Route path="condo-terms" element={ <CondoTermsPage /> } />
    <Route path="pre-order-agreement" element={ <PreOrderAgreementPage /> } />

    {/* Helper pages for install Team */}
    <Route path="electrical-requirements-console" element={ <InstallPageConsole/> } />
    <Route path="electrical-requirements-workstation" element={ <InstallPageWorkstation/> } />

  </Routes>

  {(!['/build-yours', '/order'].includes(location.pathname)) &&
  isFooterReady &&
  <Suspense fallback={<div> Loading...</div>}>
    <Footer />
  </Suspense>}

  {/* <div
    onClick={dismissMenu}
    className={`nav-backdrop ${isNavMenuUp ? 'menu-visible' :''}`}
  /> */}
</WebsiteWrapper>
}

export default BBSite;
