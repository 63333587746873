import { useState, useEffect } from "react";


export const useScrollOffset = () =>{
  const [scrollPosition, setScrollPosition] = useState(0);
  const isTopOfPage = (scrollPosition == 0)
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {scrollPosition, isTopOfPage}
}
