import BBSElectricalWorkstation from 'assets/misc/electrical-requirements-workstation.jpg'

const InstallPageWorkstation = () => <div>
  <img
    src={BBSElectricalWorkstation}
    alt='workstation eletrical diagram'
    style={{width:'100%'}}
  />
</div>

export default InstallPageWorkstation
