import React from 'react'

import {
  DocPageWrapper,
} from './styles'

const BetaTermsPage = () => {

  return <DocPageWrapper>
    <div className="subheader section">
      <h1 className="leftTitle"> Beta Program Terms and Conditions</h1>
      <div className="rightSubtitle">
      <p className=""> These Beta Program Terms and Conditions (“Beta Terms”) are attached to and apply to Customer’s (as identified in an Order Form) purchase and use of the Bumblebee System which includes the Bumblebee Products and Device Software (defined below) and the Bumblebee Services (defined below) under Bumblebee’s beta testing program (the “Beta Program”).  The Beta Program is a sale, installation and end user experience of the operation of the Bumblebee System that is not otherwise available for sale or distribution as a final released product or system when provided to Customer under the Order Form.  Although included in the Bumblebee System and subject to these Beta Terms, the Bumblebee Services will be provided to Customer for the duration of the Beta Program Period (as identified in the Order Form) in accordance with and subject to the Bumblebee Services Terms and Conditions (and licenses granted and restrictions set forth therein) as in effect from time to time which are posted on Bumblebee’s website at: [www.bumblebeespaces.com/servicesterms] (the “Services Terms”), and as such Services Terms are supplemented, modified and/or superseded by these Beta Terms.  If there is any conflict between the Services Terms and these Beta Terms, the provisions set forth in these Beta Terms shall control.
      </p>
      <p className=""> The Beta Program is provided to Customer for installation and use of the Bumblebee System in the Customer Locations (defined below) and live user (i.e. owner or resident) environment.  Use of the Beta Program provides the Customer and its owners and/or residents with early operational and user experience of the Bumblebee System and provides Bumblebee with specific Feedback (defined below) regarding the installation and operation of the Bumblebee System.  Customer understands and agrees that since the Beta Program uses Bumblebee Products, Device Software and Bumblebee Services that are newly built and currently under development, testing and modification prior to their commercial release. Customer shall fully inform its  owners and residents of the beta nature of the Bumblebee System and fully disclose and pass through the limitation of liability and warranty disclaimers set forth in Sections 9 and 10 below, by incorporating these provisions in the  lease or purchase and sale agreements with its owners and residents..
      </p>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">


        <div className="text-block">
          <h2 className="subtitle">1.          Background </h2>
          <p>
          a)              Bumblebee is in the beta production and testing stage of the following items that collectively comprise the Bumblebee System: (1) Bumblebee’s modular living and storage products containing beds and storage modules (“Bumblebee Modules”), (2) one or more electronic robotic access devices, including sensors, user interfaces, control switches and other devices if installed, containing the Device Software and Bumblebee Applications (“Bumblebee Device(s)”), (3) proprietary software embedded in the Bumblebee Devices by which the Bumblebee Products are able to operate up and down, either automatically via the Bumblebee Services, or manually by user operated control interfaces (together with any updates thereto, the “Device Software”) 4) the proprietary technologies and software (including computer generated artificial intelligence) that provide enhanced user capabilities and experiences with the Bumblebee Products, including for Customer specific and tailored means of accessing, raising, lowering, and using the Bumblebee Products (“Bumblebee Applications”), and (5) such repair and replacement services as Bumblebee makes available from time to time to repair or fix the Bumblebee Products and Bumblebee Applications when they are not performing in accordance with their current pre-release specifications (“Bumblebee Care Package Services”).
          </p>
          <p>
          b)              The Bumblebee Modules, Bumblebee Devices, and Device Software are referred to as the “Bumblebee Products.”  For clarity and the avoidance of doubt, the Device Software included in the Bumblebee Devices and licensed pursuant to these Beta Terms specifically does not include the Bumblebee Applications which are governed and licensed to Customer, if at all, as part of the Bumblebee Services by the terms and conditions set forth in the Services Terms.
          </p>
          <p>
          c)              The Bumblebee Applications and all other features, tools, services, and content provided by Bumblebee in connection with the Bumblebee System are collectively referred to as the “Bumblebee Services” as well as the Bumblebee Care Package Services and are intended for use solely with the Bumblebee Products during the Beta Program Period or as otherwise made available to Customer by Bumblebee.


          </p>
          <p>
          d)              The Bumblebee Products and Bumblebee Services collectively comprise the “Bumblebee System.”
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">2.          Definitions </h2>

          <h3 className="subtitle">In addition to the terms defined elsewhere, including in Section 1 above, the following terms have the following meanings:</h3>
          <p>
          “Beta Program Period” is the period of time that Customer is using the Bumblebee System at a Customer Location pursuant to these Beta Terms and is thereby authorized and able to access and use the Bumblebee Services, including the Bumblebee Care Package Services.
          </p>
          <p>
          “Change Order'” means a change to the initial Bumblebee System configuration pursuant to the mutual written agreement of Bumblebee and Customer.
          </p>
          <p>
          “Order Form” means the fully executed Bumblebee Beta Program Order Form setting forth the specific components and services of the Bumblebee System for use in the Beta Program and as may be amended from time to time pursuant to a Change Order.
          </p>
          <p>
          “Beta Program Price'' means the price, in United States Dollars, that Customer is paying to Bumblebee for the Bumblebee Products and Bumblebee Services being purchased and used under this Beta Program, as set forth on the Order Form, as the same may be amended from time to time pursuant to a Change Order.
          </p>
          <p>
          “Services Terms” means those separate terms and conditions pursuant to which Bumblebee provides the Bumblebee Services.
          </p>
        </div>
      </div>
    </div>




    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">3.          Delivery and Installation</h2>
          <p>
          a)              Customer shall be deemed to have irrevocably accepted the terms and conditions of these Beta Terms, the Services Terms and the Order Form upon executing the Order Form and delivering same to Bumblebee.  Order Forms cannot be canceled or amended except with Bumblebee’s written consent.  Pending or open Order Forms may not be sold, assigned, transferred or conveyed without Bumblebee’s prior written consent.  As part of the Beta Program, Bumblebee will install the Bumblebee Products in one or more apartment or Customer locations designated by Customer in the Order Form and may include a single or multiple residential spaces (“Customer Locations”).
          </p>
          <p>
          b)              Bumblebee will use reasonable commercial efforts to install the Bumblebee Products in a timely fashion and within the time estimates set forth in the Order Form.  All time frames are estimates, however, and Bumblebee and Customer recognize and agree that the installation of the Bumblebee Products depends on many factors, including (i) Bumblebee receiving the information and data from Customer required for installation, (ii) Customer providing on a timely basis the necessary technical prerequisites for the installation of the Bumblebee Products, including any building or construction permits, access requirements and permissions, internet access, or as otherwise set forth in the Bumblebee System documentation and Order Form (the “Technical Prerequisites”), and (iii) the number and type of Change Orders, if any.  Bumblebee will not be responsible for any delays in the installation of the Bumblebee Products based in whole or in part on (i) delays by Customer in providing the information required for installation, as set forth in the Order Form or Bumblebee System documentation, (ii) the delay or failure by Customer to provide the Technical Prerequisites or access to Customer Locations, and (iii) Change Orders with regard to the Bumblebee Products.
          </p>
          <p>
          c)              Customer is responsible for all requirements necessary for permission and access to deliver and install the Bumblebee Products in Customer Locations.  If Bumblebee is to deliver and install Bumblebee Products, normal delivery time and installation hours are 9:00 a.m. to 5:00 p.m. local time at the Customer Location, Monday to Friday. Customer shall obtain and be responsible for compliance with all permits and licenses required by the laws and local authorities in connection with the delivery and installation of the Bumblebee Products.  Bumblebee will comply with all of Customer’s reasonable policies and procedures relating to working at Customer’s Locations.
          </p>
          <p>
          d)              When redelivery or rescheduling of installation of Bumblebee Products is required because Customer is unable to accept the Bumblebee Products or Customer has not completed its obligations as described herein, Bumblebee will cooperate with Customer to determine a mutually agreeable date and time for delivery and installation.   Rescheduling the date and time may result in additional charges to the Customer.
          </p>
          <p>
          e)              Customer agrees that the job site shall be clean, clear and free of debris prior to installation.  Customer shall provide electric service, heat/AC, internet access, hoisting, and/or elevator service  without charge to Bumblebee.  Customer shall provide adequate facilities for off-loading, staging, moving, and handling of Bumblebee Products shall be provided.
          </p>
          <p>
          f)               Bumblebee’s ability to erect, assemble or permanently attach, affix, or bolt in place Bumblebee Products may be dependent on local laws, regulations, trade union agreements and other factors.  If any of these factors create delays or additional costs not anticipated by Bumblebee, time for installation may be adjusted and the Customer may be responsible for additional charges.  The risk of loss and responsibility for the security and safeguarding of the delivered Bumblebee Products shall pass to Customer upon delivery of the Bumblebee Products to the Customer’s Location.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">4.         Acceptance</h2>
          <p>
          a)              Upon completion of installation of the Bumblebee Equipment, Bumblebee will perform testing to determine that the Bumblebee Products are operating according to the pre-release documentation for the Bumblebee Products.  Bumblebee will notify Customer once the installation is complete and Customer may inspect and test the Bumblebee Products itself during the five (5) day period after receipt of Bumblebee’s notice.  If Customer determines that based on its own inspection the Bumblebee Products do not operate in accordance with the documentation for the Bumblebee Products, Customer must notify Bumblebee in writing of such failure within the five (5) day period following receipt of Bumblebee’s notice.  Failure to deliver written notice of any issue related to the operation of the Bumblebee Equipment within five (5) days of Bumblebee’s notice of installation completion shall constitute acceptance of all Bumblebee Products for which Customer had been notified.  Further, final payment by Customer of any fees or other consideration to Bumblebee under this Beta Program shall  constitute acceptance.  If the installation of the Bumblebee Products is rejected by Customer in writing within the five (5) day acceptance period with a reasonable description and supporting documentation/photos, that the installation of the Bumblebee Products did not perform in accordance with the documentation for the Bumblebee Products, Bumblebee and Customer shall work cooperatively on a mutually acceptable plan for resolving and fixing the issues raised by Customer.
          </p>
        </div>
      </div>
    </div>



    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">5.          Program Price</h2>
          <p>
          a)              Customer agrees to pay the entire Beta Program Price for the Bumblebee Products and Bumblebee System.  Customer will pay the Beta Program Price in accordance with the payment terms set forth in the Order Form or if no payment terms are set forth in the Order Form then the Beta Program Price for the Bumblebee Products shall be paid fifty percent (50%) upon execution of the Order Form and the balance within thirty (30) days of Bumblebee’s completion of the installation. All payments of the Beta Program Price are nonrefundable.  Failure by Customer to make timely payment of all or any portion of the Beta Program Price will result in delays in the installation of the Bumblebee Products. Bumblebee will not be responsible for any delays in the installation caused in whole or in part on the failure of Customer to make timely payment.
          </p>
          <p>
          b)              In addition to the Beta Program Price, Customer will reimburse Bumblebee for all reasonable travel, lodging and out-of-pocket expenses related to the installation of the Bumblebee Products if such expenses are approved in writing in advance by Customer.  Bumblebee shall provide Customer with invoices, in reasonable detail, for all such costs, expenses and fees. Customer shall reimburse Bumblebee for such expenses and fees within thirty (30) days from the date of invoice.
          </p>
          <p>
          c)              Customer will reimburse Bumblebee for any sales, use, or other taxes assessed to Bumblebee arising from the purchase by Customer of the Bumblebee Products.
          </p>
          <p>
          d)              Bumblebee reserves the right to suspend any further performance in the event payment is not made when due. Interest charges will be added to overdue invoices at the rate of 1.5% per month (subject to any limit imposed by applicable law).  Payment for Bumblebee Products shall not be subject to offset, deduction or back charges by Customer, unless such offset, deduction or back charge was a sole, direct and proximate result of gross negligence or willful misconduct on the part of Bumblebee and such offset, deduction or back charge is expressly accepted in writing by an authorized representative of Bumblebee.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">6.          Change Orders</h2>
          <p>
          Customer from time to time may request changes to the Bumblebee System configuration.  Any such changes to the Bumblebee System shall become effective only upon execution of a Change Order by an authorized representative of Bumblebee and Customer. Bumblebee shall not decline any change requests by Customer that increase the cost or functionality of the Bumblebee System, provided the changes are reasonable in scope and a commensurate increase in charges and fees is agreed to by Bumblebee and Customer.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">7.         Device Software</h2>
          <p>
          Upon full payment of the Beta Program Price and for so long as Customer is not in default or breach of its obligations under these Beta Terms or the Services Terms, and these Beta Terms remain in effect, Bumblebee grants to Customer a limited, non-exclusive and/or sublicense (hereinafter “License”) to use the Device Software in connection with the Customer’s use and operation of the Bumblebee Devices.  Any charge for the License is included in the Beta Program Price for the Bumblebee Devices listed in the Order Form.  Use of the Device Software under this License is subject to the Bumblebee End User License Agreement (“EULA”) which is found on Bumblebee’s website at [www.bumblebeespaces.com/EULA] and may also be subject to Bumblebee’s Privacy Policy and such other terms, conditions and instructions that may be provided by Bumblebee from time to time.  By using the Device Software under this License, Customer is agreeing to the terms of all of the above-listed terms and conditions that apply to Customer’s use.  Any future release, update, or other addition or change to functionality of the Device Software shall be subject to the terms of the EULA, unless Bumblebee expressly states otherwise and Customer’s continued use of the Device Software is its agreement to the terms and conditions set forth in the EULA.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">8.          Bumblebee System Support </h2>
          <p>
          a)              Subject to the limitations set forth in this Section 8 below, and only during the Beta Program Period, Customer and the owners or residents of the Customer Location where the Bumblebee System is installed will have access to the Bumblebee Care Package Services that Bumblebee provides as part of the Bumblebee Services under the Services Terms.  Customer agrees that any Bumblebee Products that are replaced under the Bumblebee Care Package Services shall be returned to Bumblebee at Customer’s expense during the Beta Program Period.  At the conclusion of the Beta Program Period as specified in the Order Form, the Bumblebee Services shall be automatically renewed on a month-to-month basis without additional charge to Customer, with all Bumblebee Services continuing under these Beta Terms until the earlier of either (i) the date that Bumblebee removes the Bumblebee System from the Beta Program by providing Customer with written notice that Bumblebee has released the Bumblebee System to the general public, or (ii) the effective date of termination as specified in the written notice that Bumblebee provides Customer advising that Bumblebee will no longer support the Bumblebee System under these Beta Terms.  At such time the Bumblebee Services will be  terminated and Customer (or the applicable Account Owner of the Bumblebee System operating under these Beta Terms) shall automatically and without further action on the part of any party be converted into a standard Bumblebee Services account governed in full under the Services Terms with the Initial Service Period (as defined in the Services Terms) beginning on the effective date of the termination of the Bumblebee Services under these Beta Terms.
          </p>
          <p>
          b)              Bumblebee will have no obligations to provide Bumblebee Care Package Services with respect to any portion of the Bumblebee System if Bumblebee determines that any Bumblebee Product has been damaged due to (i) misuse, accident (including, without limitation, collision, fire and the spillage of liquid or food), neglect, abuse, alteration, unusual stress or modification; (ii) improper or unauthorized repair, installation, testing, or storage; (iii) operation of any Bumblebee Product outside the permitted or intended uses described by Bumblebee in its documentation or on its website; (iv) use with third party products or parts for which any Bumblebee Product or Bumblebee Service was not intended for use in conjunction with; (v) breakdowns, fluctuations, or interruptions in electric power or telecommunications networks, or (vi) acts of God and other forces beyond Bumblebee's reasonable control, including, but not limited to, the internet, hacking, viruses, pandemics, government ordered shutdowns, power outages, wars, riots, terrorism, labor disputes, earthquakes, floods or other events of "force majeure."  Customer assumes all risk associated with the suitability, installation, interoperability and performance of the Bumblebee System with any third-party components, hardware, software or service not provided by Bumblebee.
          </p>
        </div>
      </div>
    </div>



    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">9.          No Warranty for Bumblebee System Under Beta Program </h2>
          <p>
          a)              As the Bumblebee System is still under development, testing, and modification and is being used in a pre-release setting, the Bumblebee System may or may not have bugs, glitches, defects or other reasons that could cause product, service, or system failure.  Further, the commercially released Bumblebee Products may differ in assembly and installation methods and materials from those delivered as part of the Beta Program. By their nature, the Bumblebee System delivered and used by Customer, residents and owners under the Beta Program are “pre-release” and are not at the level of manufacturing, materials, performance and functionality as may be included in the final, generally available, product and services offering, and the Beta Program Price paid by Customer reflects the beta nature of the Bumblebee System.  Because the Bumblebee System provided under the Beta Program is subject to change, Bumblebee reserves the right to alter the Bumblebee System under the Beta Program at any time, and any reliance on the Bumblebee System under the Beta Program is at the sole risk of the Customer and its owners and residents.
          </p>
          <p>

          b)              ALTHOUGH CUSTOMER WILL RECEIVE THE BENEFITS OF THE BUMBLEBEE CARE PACKAGE SERVICES DURING THE BETA PROGRAM PERIOD, THOSE SERVICES ARE NOT WARRANTY SERVICES AND THE BUMBLEBEE SYSTEM IS PROVIDED TO CUSTOMER UNDER THE BETA PROGRAM “AS-IS” AND WITHOUT WARRANTY OF ANY KIND.  BUMBLEBEE DOES NOT MAKE ANY, AND HEREBY DISCLAIMS ALL, EXPRESS OR IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION ANY AND ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE, ACCURACY, RELIABILITY, AND NON-INFRINGEMENT.  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, CUSTOMER HEREBY ASSUMES ALL RISKS AND RESPONSIBILITIES, LOSS OR LIABILITY OF ANY KIND ASSOCIATED WITH THE INSTALLATION AND OPERATION OF THE BUMBLEBEE SYSTEM BEING OPERATED UNDER THE BETA PROGRAM AND CUSTOMER SHALL BE RESPONSIBLE AND LIABLE FOR (1) ANY PROPERTY DAMAGE TO CUSTOMER’S RESIDENCE, DEVICES, PROPERTY OR ANY PROPERTY OF A THIRD PARTY, AND/OR (2) PERSONAL AND/OR BODILY INJURY, INCLUDING DEATH, SUFFERED BY CUSTOMER, CUSTOMER’S OWNERS AND RESIDENTS OR ANY THIRD PARTY,  IN CONNECTION WITH THE BETA PROGRAM.  CUSTOMER SHALL LOOK TO ITS OWN RESOURCES, INSURANCE POLICIES, ANY RESPONSIBLE THIRD PARTY (OTHER THAN BUMBLEBEE), OR THE INSURANCE POLICIES  OF ANY RESPONSIBLE THIRD PARTY (OTHER THAN BUMBLEBEE) FOR ANY CLAIMS OR DAMAGES IN CONNECTION WITH THE BUMBLEBEE SYSTEM UNDER THE BETA PROGRAM.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            10.       LIMITATION OF DAMAGES </h2>
          <p>
          IN ADDITION TO THE ABOVE WARRANTY DISCLAIMERS, TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER BUMBLEBEE NOR ITS SUPPLIERS SHALL BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THE ORDER FORM OR THESE BETA TERMS INCLUDING FOR CLAIMS ARISING FROM ALLEGED BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER THEORY FOR (A) LOSS OR INACCURACY OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR (B) ANY INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES INCLUDING, BUT NOT LIMITED TO BUSINESS INTERRUPTION, LOSS OF REVENUES, OR LOSS OF PROFITS. THE FOREGOING SHALL APPLY EVEN IF BUMBLEBEE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT PERMITTED BY LAW, BUMBLEBEE’S AGGREGATE CUMULATIVE LIABILITY HEREUNDER SHALL NOT EXCEED THE AMOUNT ACTUALLY PAID FOR THE BUMBLEBEE SYSTEM BY THE ORIGINAL PURCHASER.  CERTAIN STATES, PROVINCES OR JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE EXCLUSIONS SET FORTH ABOVE MAY NOT APPLY TO CUSTOMER.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            11.       Indemnification </h2>
          <p>
          Subject to and limited by the provisions of Sections 8, 9 and 10, Bumblebee agrees to indemnify, defend and hold harmless Customer against all damages, claims, liabilities, losses and other expenses, including without limitation reasonable attorneys' fees and costs, that arise directly out of any claim that the Bumblebee Products infringes any third party's patents, copyrights or trademarks. In addition to its indemnification obligation above, and without additional cost to Customer, Bumblebee shall do one of the following: (a) procure for Customer the right to continue using the infringing Bumblebee Products without liability of any kind; or (b) modify or replace the infringing Bumblebee Product so that it is not infringing without material loss of functionality or substantially increased costs of use, operation or maintenance. Customer agrees to indemnify, defend, release, and hold harmless Bumblebee, its partners, licensors, affiliates, contractors, officers, directors, employees and agents from all damages, losses and expenses arising directly or indirectly from or in any manner relating to (a) any negligent acts, omissions or willful misconduct by Customer, (b) any breach of these Beta Terms by Customer, and/or (c) Customer’s violation of any law or of any rights of any third party.
          </p>
        </div>
      </div>
    </div>



    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            12.       Technical Advice  </h2>
          <p>
          Except for support provided under the Bumblebee Care Package Services, Bumblebee assumes no obligation or liability, express or implied, with respect to any recommendations, opinions, or advice as to the purchase, installation, or use of the Bumblebee System.  Except for support provided under the Services Terms, including the Bumblebee Care Package Services, all recommendations, opinions, marketing or promotional materials or advice are given and shall be accepted at Customer's own risk and shall not constitute any warranty or guaranty of the Bumblebee System or its performance or use.  Customer is relying on its own knowledge and advice with regard to its participation in the Beta Program.
          </p>
        </div>
      </div>
    </div>



    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
          13.       Beta Program Feedback   </h2>
          <p>
          Customer agrees to test and evaluate the Bumblebee System and report to Bumblebee on the functionality and usefulness of the Bumblebee System.  As part of the Beta Program, and upon Bumblebee’s reasonable advanced request, Customer will allow Bumblebee’s technical staff access to Customer’s Location if necessary to identify or fix or improve the Bumblebee System or to monitor the Bumblebee System’s performance during the Beta Program Period.  Such access may be supervised by Customer and will be subject to reasonable controls to ensure the protection of Customer's premises and systems.  All non-public information disclosed by either party in connection with the Beta Program will be "Confidential Information."  Customer agrees to maintain all such Confidential Information strictly confidential and not to share such information with any third party. Customer may provide to Bumblebee suggested improvements, feedback or comments regarding the functions, features, performance and other characteristics of the Bumblebee System during the Beta Period (collectively "Feedback").  Customer agrees that all Feedback is owned exclusively by Bumblebee, is the Confidential Information of Bumblebee, and that Bumblebee is free to use and otherwise exploit Feedback in any manner and at any time during or after the Beta Program, provided it will not publicly associate Customer’s name with the Feedback without Customer’s prior consent.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
          14.       Bumblebee System is Bumblebee Trade Secrets   </h2>
          <p>
          The Bumblebee System is proprietary to, and the valuable trade secrets of, Bumblebee. It is entrusted to Customer only for the purposes set forth in these Beta Terms.  Customer will not, without Bumblebee’s prior written consent: (a) disclose any Confidential Information about the Bumblebee System, its design and performance specifications, any applicable code, or the results or Feedback to anyone other than Customer’s employees or residents and owners of the units in which the Bumblebee System is installed and who shall be subject to nondisclosure restrictions at least as protective as those set forth in these Beta Terms; or (b) reverse engineer, decompile or disassemble the Bumblebee System or any portion thereof.
          </p>
        </div>
      </div>
    </div>



    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            15.       Title and Risk of Loss; Insurance  </h2>
          <p>
          Title to, and risk of loss for, the Bumblebee Products shall pass from Bumblebee to Customer upon delivery of the Bumblebee Products to Customer’s Location and Customer’s acceptance of the Beta Terms.  Customer, at its risk and expense, shall obtain any required authorizations for the use of the Bumblebee System. Customer shall obtain and maintain adequate insurance from a reputable insurance carrier with liability and property damage limits of at least One Million Dollars  ($1,000,000) providing coverage for, among other things, third party property damage, personal injury, any loss or damage to the Bumblebee System and any loss or damage arising from the use or operation of the Bumblebee System, at Customer’s Location and at all times during the Beta Program Period.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            16.       Compliance with Laws  </h2>
          <p>
          Each party shall comply with all applicable international, national, federal, state, municipal and local laws, rules, regulations and ordinances now in effect or hereafter enacted relating to the manufacture, operation, maintenance, use, delivery and installation of the Bumblebee System.  Customer will refrain from any act or omission that will cause Bumblebee to be in violation of any federal, state or local laws, regulations, statutes, orders, ordinances or other legal requirements regarding the installation and use of the Bumblebee System.   Nothing in this provision limits or modifies the Customer’s responsibility to secure and pay for all permits, approvals, easements, assessments, and fees required for the construction, use, installation of the Bumblebee System at Customer’s Location.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            17.       Force Majeure  </h2>
          <p>
          If Bumblebee shall be rendered wholly or partly unable to carry out its obligations under these Beta Terms by reason of an event beyond its reasonable control, including but not limited to acts of God, the internet, hacking, viruses, pandemics, power outages, wars, riots, terrorism, labor disputes, earthquakes, government action, labor troubles, production or engineering difficulties, or inability to obtain or shortage of raw materials, parts, equipment, or transportation, then the performance of such obligations, insofar as they are affected by such cause, shall be excused during the continuance of the inability so caused.
          </p>
        </div>
      </div>
    </div>



    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            18.       Severability </h2>
          <p>
          If any term or provision of these Beta Terms shall, to any extent, be determined to be invalid or unenforceable by a court or body of competent jurisdiction, the remainder of these Beta Terms shall not be affected thereby, and each term and provision of these Beta Terms shall be valid and enforceable to the fullest extent permitted by law.
          </p>
        </div>
      </div>
    </div>



    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            19.       Governing Law and Jurisdiction </h2>
          <p>
          These Beta Terms and the sale, delivery and installation of the Bumblebee Products to Customer shall be governed by and construed in accordance with the laws of the State of California, including its conflicts of law rules. Customer agrees that any dispute arising from or relating to the subject matter of these Beta Terms shall be governed by the exclusive jurisdiction and venue of relief in any state or federal court located in the State of California.
          </p>
        </div>
      </div>
    </div>



    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            20.       No Waiver </h2>
          <p>
          If any portion of these Beta Terms are found to be unenforceable or invalid, the remaining portion will remain in full force and effect. If Bumblebee fails to enforce any of these Beta Terms, it will not be considered a waiver.  Any waiver of these Beta Terms must be made in writing and signed by Bumblebee.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            21.       Transfer </h2>
          <p>
          Customer cannot transfer any of its rights or obligations under these Beta Terms without the consent of Bumblebee. Bumblebee may assign, transfer, or delegate any of its rights and obligations without prior consent, whether as part of a merger, acquisition, sale of assets, or otherwise.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            22.       No Agency or Authority  </h2>
          <p>
          No agency, partnership, joint venture, or employment relationship is created as a result of these Beta Terms and neither party has any authority of any kind to bind the other in any respect.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">
            23.       Section Headings </h2>
          <p>
          The section and paragraph headings in these Beta Terms are for convenience only and shall not affect their interpretation.
          </p>
        </div>
      </div>
    </div>



    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <p>
            Effective Date of Terms: ____ __, 2020.
          </p>
        </div>
      </div>
    </div>














  </DocPageWrapper>
}

export default BetaTermsPage
