import styled from "styled-components";
import Colors from "../Utils/Colors";
import {
  borderRadii,
  screen,
  // barHeight,
  navDropTime,
  menuHeight,
  mobileMenuHeight,
} from "../Utils/globalStyles";

export const NavWrapper = styled.nav`
  z-index: 50;
  position: fixed;
  left: 0;
  right: 0;

  .show {
    opacity: 1;
    margin-left: 20px;
    transition: opacity 0.15s;
    @media (${screen.mobile}) {
      margin-left: 15px;
    }
  }

  .hide {
    opacity: 0;

    @media (${screen.mobile}) {
      opacity: 1;
    }
  }

  li {
    width: fit-content;
  }
  h2 {
    display: inline-block;
  }

  .menu-visible {
    transform: translate(0, calc(${menuHeight}));

    @media (${screen.mobile}) {
      transform: translate(0, calc(${mobileMenuHeight}));
    }
  }
`;
export const NavBarWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  -webkit-backface-visibility: hidden;
  position: fixed;
  padding: 20px;
  top: 0;
  z-index: 100;
  left: 0;
  right: 0;
  padding-left: 32px;
  padding-right: 32px;

  @media (${screen.mobile}) {
    border-bottom: 1px solid ${Colors.Grey2};
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 4px;
    padding-left: 14px;
    padding-right: 12px;
  }

  .logoWrapper {
    display: flex;
    align-items: center;
  }

  img {
    top: 0px;
    left: -3px;
    position: relative;
  }
`;
export const NavLinkMenu = styled.div`
  position: relative;
  opacity: 1;
  @media (${screen.mobile}) {
    /* padding: 100px 20px 40px; */
  }

  .nav-bottom-row {
    align-items: flex-end;
    ul h3 {
      margin: 0;
      @media (${screen.iPad}) {
        margin: unset;
      }
    }
    @media (${screen.iPad}) {
      flex-direction: row;
      align-items: flex-end;
    }

    .socials {
      width: unset;
      padding-top: 25px;
      padding-bottom: 0px;
      img {
        height: 32px;
        margin-right: 20px;
        width: unset;
      }
    }
  }

  button {
    ${
      "" /* position: relative;
    left: -12px; */
    }
    .link {
      h2 {
        color: ${Colors.white};
        line-height: unset;
      }
    }
  }

  > a {
    font-size: 25px;
    fontweight: bold;
    h2 {
      height: calc(32px - 22px + 8 * ((100vw - 320px) / 1280));
      text-decoration: none;
    }
  }
  ul {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
    padding-top: 60px;

    li {
      padding-top: 20px;
    }
  }
`;

export const ImgWrapper = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .bumblebee {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  span {
    font-size: 33px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  img {
    width: 33px;
    height: 33px;
  }

  span {
    font-size: 33px;
  }
`;

export const FooterWrapper = styled.footer`
  background-color: ${Colors.White};
  padding: 40px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  @media(${screen.mobile}) {
    padding: 20px;
    .newsLetterWrapper { width: 100%; }
  }

  .textWrapper {
    padding-top:5px;
    display: flex;
    justify-content: space-between;
  }

  .top-row,
  .mid-row,
  .bottom-row {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }h2 {
    margin-bottom:20px;
  }

  .bottom-row {
    padding-top:25px;
  }

  @media(${screen.mobile}) {
    padding: 20px;
    padding-top: 40px;

    div.top-row h2,
    div.mid-row nav{
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    }

    >div{
      flex-direction: column-reverse;
    }

    div.top-row h2 {font-size: 24px}
    div.socials{
      flex-direction: row;
      justify-content: end;
    }
  }

  .top-row {
    margin-bottom: 160px;
    align-items: flex-start;

    @media(${screen.iPad}) {
      flex-direction: column;
    }
  }

  .bottom-row {
    padding-top:15px;
    flex-direction: row;
    align-items: flex-end;
  }

  .textWrapper {
    display:flex;
    flex-direction:column;
  }

  .terms {
    flex:1;
    padding-top:5px;
  }

  .bumblebee {
    flex:1;
    font-weight:500;
    line-height 100%;
  }

  .socials {
    display: flex;
    flex-direction: row-reverse;
    text-align: end;

    .iconWrapper {
      display: flex;
      height:25px;
      margin-top:auto;
      width:25px;
      margin-left: 20px;
    }
  }
`;

export const NewsLetterForm = styled.form`
  border-bottom: 1px solid rgb(150, 150, 150);
  display: flex;
  border-radius: 0px;
  align-self: start;
  margin-bottom: 5px;

  @media (${screen.mobile}) {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }

  .error {
    padding-top: 20px;
  }

  form {
    display: flex;
  }
  input {
    padding-left: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 0px;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0);
    color: rgb(150, 150, 150);
    width: 100%;

    ::placeholder {
      color: rgb(150, 150, 150);
    }
  }

  button {
    border: none;
    color: black;
    ${borderRadii}
    padding-left:15px;
    background-color: transparent;
    cursor: pointer;
  }

  @media (${screen.mobile}) {
    justify-content: space-between;
  }
`;

export const TextBlockWrapper = styled.div`
  width: min(90vw, 620px);
  margin-top: 160px;
  margin-bottom: 50px;
  @media (${screen.iPad}) {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  display: flex;
  flex-direction: column;
  align-self: ${({ isTextLeft }) => (isTextLeft ? "flex-start" : "flex-end")};

  &.sec1 {
    margin-top: 80px;
  }

  &.cta {
    margin-top: 30px;
  }
  &.vid-block {
    margin: 40px 100px;
    @media (${screen.iPad}) {
      margin: 40px;
    }
    @media (${screen.mobile}) {
      margin: 20px;
    }
  }

  &.hero-block {
    margin-top: 0;
    p {
      width: 250px;
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
  }
`;

export const HeroBlockWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  @media (${screen.iPad}) {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  display: flex;
  flex-direction: column;
  align-self: ${({ isTextLeft }) => (isTextLeft ? "flex-start" : "flex-end")};

  * {
    margin: 0.6rem 0;
  }
  h4 {
    font-size: 2.6rem;
    line-height: 2.9rem;
    font-family: "MaisonNeue-Light";
  }

  a {
    padding: 0rem 0;
  }

  &.sec1 {
    margin-top: 80px;
  }

  &.cta {
    margin-top: 30px;
  }
  &.vid-block {
    margin: 40px 100px;
    @media (${screen.iPad}) {
      margin: 40px;
    }
    @media (${screen.mobile}) {
      margin: 20px;
    }
  }

  &.hero-block {
    margin-top: 0;
    p {
      width: 250px;
    }
  }
`;

export const ImageTogglerWrapper = styled.div`
  position: relative;
  width: 100%;

  img {
    top: 0;
    bottom: 0;
    max-width: 100%;
    position: absolute;
    transition: opacity 0.2s ease-in-out;

    &.background {
      position: unset;
      visibility: hidden;
      z-index: -1;
    }

    &.hidden {
      opacity: 0;
      z-index: 1;
      transition: opacity 0.5s ease-in-out;
    }

    &.active {
      z-index: 3;
      opacity: 1;
    }
  }

  div.pic-toggler-row {
    z-index: 4;
    display: flex;
    flex-direction:row;
    margin-right: auto;
    position: absolute;
    bottom: 20px;
    ${({ isTextLeft }) => (isTextLeft ? "left: 20px;" : "right: 20px;")};
    @media (${screen.iPad}) {
      bottom: 10px;
      ${({ isTextLeft }) => (isTextLeft ? "left: 10px;" : "right: 10px;")};
    }
    @media (max-width: 380px) {
      flex-direction:column;
      gap:5px;
    }
  }
  .checkpoint {
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    transition: all 0.15s;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    @media (${screen.iPad}) {
      margin-right: 8px;
    }
    @media (max-width: 380px) {
      margin: 0px;
    }
  }
  .checkpoint:hover {
    background-color: rgba(220, 220, 220, 0.8);
  }
  .checkpoint:hover {
    background-color: rgba(220, 220, 220, 0.8);
  }
`;

export const ProductPanelWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 2rem;

  img {
    height: 100%;
  }

  :first-child {
    padding-left: 0;
  }
  :last-child {
    padding-right: 0;
  }

  div {
    display: flex;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 2.5rem;
    font-family: MaisonNeue;
    margin-bottom: 1.2rem;
  }
  p {
    font-size: 1.8rem;
    max-width: 25ch;
  }
`;
