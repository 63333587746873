import React from 'react'

import {
  DocPageWrapper,
} from './styles'

const PreOrderAgreement = () => {

  return <DocPageWrapper>
    <div className="subheader section">
    <div className="leftTitle">
      <h1> Pre-Order Agreement</h1>
      <h1> Terms and Conditions</h1>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">


        <div className="text-block">
          <h2 className="subtitle">Documentation</h2>
          <p className="subtitle strong">This Bumblebee Spaces Pre-Order Agreement (the “Agreement”) between you and Bumblebee Spaces, Inc. or its affiliate (“Bumblebee,” “we,” “us” or “our”) is made up of the following documents:</p>
          <p>
          1. Bumblebee Spaces Conﬁguration: The Bumblebee Conﬁguration will be conﬁrmed with you at a later date. It will describe the product that you configure, including pricing (excluding taxes and official or government fees) (the “Bumblebee Configuration”).
          </p>
          <p>
          2. Final Price Sheet: The Final Price Sheet will be provided to you as your delivery date nears. It will include final pricing based on your Bumblebee Configuration and will include taxes and official or governmental fees (the “Final Price Sheet”).
          </p>
          <p>
          3. Terms & Conditions: These Terms & Conditions are effective as of the date you place your pre-order and make your Pre-Order Payment (the “Pre-Order Date”).
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">Agreement to Purchase</h2>
          <p>
          You agree to pre-order the Bumblebee products and services (the “Bumblebee System”) that you conﬁgure when you make your Pre-Order Payment and will complete the transaction when the Bumblebee System is ready for delivery and installation, pursuant to the terms and conditions of this Agreement. Your Bumblebee System is priced and configured based on features and options available at the time of order and you can confirm availability with a Bumblebee representative. Options, features or hardware released after you place your order may not be included in or available for your Bumblebee.</p>
        </div>
      </div>
    </div>



    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">Price, Taxes and Official Fees</h2>
          <p>
          The price of the Bumblebee System will be confirmed in your Bumblebee Conﬁguration and Final Price Sheet. As you may have only configured part of your Bumblebee System, any price provided to you in advance of the Final Price Sheet is only being offered to you as an estimate and is subject to change. Any price listed in the Bumblebee Configuration will not include taxes and official or government fees, which could amount to up to 10% or more of the Bumblebee System price. Because these taxes and fees are constantly changing and will depend on many factors, they will be calculated closer to the time of delivery and indicated on your Final Price Sheet. You are responsible for paying these additional taxes and fees. If you present a check for any payment, we may process the payment as a normal check transaction, or we may use information from your check to make a one-time electronic fund transfer from your account, in which case your bank account will reflect this transaction as an Electronic Fund Transfer.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">Pre-Order Process; Cancellation; Changes</h2>
          <p>
          After you submit your completed pre-order and the options you selected become available in production, we will invite you to complete the configuration of your Bumblebee System. We will then issue you the Bumblebee Conﬁguration and Final Price Sheet based on the base price of the configuration and any options included or that you select. Your Pre-Order Payment covers the cost of these design and configuration activities and other processing costs and is not a deposit for the Bumblebee Configuration.
          </p>
          <p className="strong">
          Until your Bumblebee Configuration is in design/configuration, you may cancel your pre-order at any time, in which case you will receive a full refund of your Pre-Order Payment. Until your final configuration is in production, you may make changes to your Bumblebee Configuration.
          </p>
          <p>
          If you make changes to the configuration of the Bumblebee, you may be subject to potential price increases for any pricing adjustments made since your original Pre-Order Date. Any changes made by you to your Bumblebee Conﬁguration, including changes to the delivery location or estimated delivery date, will be reﬂected in a subsequent Bumblebee Conﬁguration that will form part of this Agreement. This Pre-Order Payment and this Agreement are not made or entered into in anticipation of or pending any conditional sale contract.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">Delivery</h2>
          <p>
          You agree to schedule and take delivery of your Bumblebee System within one week of the estimated delivery date. If you are unable to take delivery within the specified period, your Bumblebee System may be made available for sale to other customers.
          </p>
          <p>
          You agree to schedule and take delivery of your Bumblebee System within one week of the estimated delivery date. If you are unable to take delivery within the specified period, your Bumblebee System may be made available for sale to other customers.
          </p>
        </div>
      </div>
    </div>

    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block box">
          <h2 className="subtitle">Agreement to Arbitrate</h2>
          <p>
          Please carefully read this provision, which applies to any dispute between you and Bumblebee. If you have a concern or dispute, please send a written notice describing it and your desired resolution to resolutions@bumblebeespaces.com
          </p>
          <p>
          If not resolved within 60 days, you agree that any dispute arising out of or relating to any aspect of the relationship between you and Bumblebee Spaces will not be decided by a judge or jury but instead by a single arbitrator in an arbitration administered by the American Arbitration Association (AAA) under its Consumer Arbitration Rules. This includes claims arising before this Agreement, such as claims related to statements about our products.
          </p>
          <p>
          We will pay all AAA fees for any arbitration, which will be held in the city or county of your residence. To learn more about the Rules and how to begin an arbitration, you may call any AAA office or go to www.adr.org.
          </p>
          <p>
          The arbitrator may only resolve disputes between you and Bumblebee and may not consolidate claims without the consent of all parties. The arbitrator cannot hear class or representative claims or requests for relief on behalf of others purchasing Bumblebee configurations. In other words, you and Bumblebee may bring claims against the other only in your or its individual capacity and not as a plaintiff or class member in any class or representative action. If a court or arbitrator decides that any part of this agreement to arbitrate cannot be enforced as to a particular claim for relief or remedy, then that claim or remedy (and only that claim or remedy) must be brought in court and any other claims must be arbitrated.
          </p>
          <p>
          If you prefer, you may instead take an individual dispute to small claims court.
          </p>
          <p>
          You may opt out of arbitration within 30 days after signing this Agreement by sending a letter to: Bumblebee Spaces, Inc.; 1004 Treat Ave; San Francisco, CA 94110 stating your name, Order number, and intent to opt out of the arbitration provision. If you do not opt out, this agreement to arbitrate overrides any different arbitration agreement between us, including any arbitration agreement in a finance contract.
          </p>
        </div>
      </div>
    </div>





    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">Limitation of Liability</h2>
          <p>
          To the fullest extent permitted by law, we are not liable for any incidental, special or consequential damages arising out of this Agreement. Your sole and exclusive remedy under this Agreement will be limited to reimbursement of your Pre-Order Payment.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">No Resellers; Discontinuation; Cancellation</h2>
          <p>
          Bumblebee sells Bumblebee Configurations directly to end-consumers, and we may unilaterally cancel any order that we believe has been made with a view toward resale of the Bumblebee System or that has otherwise been made in bad faith. We may also cancel your pre-order and refund your Pre-Order Payment if we discontinue a product, feature, or option after the time you place your pre-order or if we determine that you are acting in bad faith.
          </p>
        </div>
      </div>
    </div>

    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">Governing Law; Integration; Assignment</h2>
          <p>
          This Agreement and any action related thereto will be governed by the laws of the State of California, without regard to its conflict of laws provisions. The parties expressly consent to personal and exclusive jurisdiction in the state and federal courts located in the County of San Francisco, and you and Bumblebee each waive any objection to jurisdiction and venue in such courts. Prior agreements, oral statements, negotiations, communications, or representations about the Bumblebee sold under this Agreement are superseded by this Agreement. Terms relating to the pre-order not expressly contained herein are not binding. We may assign this Agreement at our discretion to one of our affiliated entities.
          </p>
        </div>
      </div>
    </div>


    <div className="section">
      <div className="space" />
      <div className="text-wrapper">
        <div className="text-block">
          <h2 className="subtitle">State Speciﬁc Provisions</h2>
          <p>
          For NEW YORK residents: If the Bumblebee System is not delivered in accordance with the Agreement within 30 days following the estimated delivery date, you have the right to cancel the Agreement and receive a full refund, unless the delay in delivery is attributable to you.
          </p>
          <p>
          This Agreement is entered into and effective as of the date you accept this Agreement, by electronic means or otherwise. By conﬁrming and accepting this Agreement, you agree to the terms and conditions of this Agreement.
          </p>
        </div>
      </div>
    </div>










  </DocPageWrapper>
}

export default PreOrderAgreement
