import styled from 'styled-components'
import {screen, PageWrapper } from '../../Utils/globalStyles'

export const ElectricalRequirementsPageWrapper = styled(PageWrapper)`
  position: relative;
  padding-top:130px;
  width: calc(100% - 40px);

  .section {
    padding-bottom:80px;
  }
  .subtitle {
    padding-bottom: 40px;
  }

  p {
    padding-bottom:40px;
    @media(${screen.mobile}) {
      padding-bottom:30px;
  }
  }
  .space {
    flex: 1;
  }

  .text-block {
  max-width:800px;
  padding-top:50px;
  padding-bottom:50px;

  @media(${screen.mobile}) {
  padding-top:50px;
  padding-bottom:50px;
  }

  }

  .subheader {
    flex:1;
    @media(${screen.mobile}) {
      padding-top:50px;
  }
  }

  .leftTitle {
    flex: 1;
    @media(${screen.mobile}) {
  display:none;
  }

  }

`
